// components
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import Button from "../Common/Button";
import TextInput from "../Common/TextInput";
import SelectInputMember from "../Common/SelectInputMember";
import SelectInput from "../Common/SelectInput";
import { notify } from "../../util";
import { updateUser, getUserById } from "../../apis/userApi";
import { updateWpUser, getWpByEmail } from "../../apis/wpApi";
import { updateExpWpUser, getExpWpByEmail } from "../../apis/expApi";
import { getMembership } from "../../apis/membershipApi";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

export default function CardEditUser() {
  const params = useParams();

  const { id } = params;

  // global state
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const { data } = await getMembership(token);
    setMembershipData(data);
    const { data: userData } = await getUserById(id, token);
    setCentralData(userData);
    setTimeout(async () => {
      if (userData) {
        const { data } = await getWpByEmail(userData.email, token);
        setWpValue(data);
      }
    }, 1000);
  }, [id, token]);

  useEffect(async () => {}, []);

  const history = useHistory();

  // State
  const [centralData, setCentralData] = React.useState({
    name: "",
    email: "",
    contactNumber: "",
    userId: "",
    ikonsBalance: 0,
    membershipStartDate: "",
    membershipTier: "",
    membershipRenewal: "",
    isPartner: false,
    isAdmin: false,
    isActive: false,
    membershipNominee: "",
  });

  const [memebershipData, setMembershipData] = React.useState();

  const [wpValue, setWpValue] = React.useState({
    first_name: "",
    last_name: "",
  });

  const {
    name,
    email,
    contactNumber,
    userId,
    ikoinsBalance,
    membershipStartDate,
    membershipTier,
    membershipRenewal,
    isPartner,
    isAdmin,
    isActive,
    membershipNominee,
  } = centralData;

  const handleChange = (e) => {
    setCentralData({ ...centralData, [e.target.name]: e.target.value });
  };

  const { first_name, last_name } = wpValue;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateUser(id, centralData, token);

      await updateWpUser(centralData.email, wpValue, token);
      await updateExpWpUser(centralData.email, wpValue, token);
    } catch (error) {
      console.log(error);
    } finally {
      notify("User updated successfully", "success");
      history.push("/admin/Users");
    }
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100">
        <div className="px-6 py-6 mb-0 bg-white rounded-t">
          <div className="flex justify-between text-center">
            <h6 className="text-xl font-bold text-blueGray-700">
              Create Account
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
          <form>
            <h6 className="mt-3 mb-6 text-sm font-bold uppercase text-blueGray-400">
              Centralized User Information
            </h6>
            <div className="flex flex-wrap">
              <TextInput
                text="Name"
                name="name"
                value={name}
                onChange={handleChange}
                placeholder="Enter Name"
                type="text"
              />

              <TextInput
                text="Email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Enter Email"
                type="email"
                disabled
              />

              <TextInput
                text="Contact Number"
                name="contactNumber"
                value={contactNumber}
                onChange={handleChange}
                placeholder="Enter Contact Number"
                type="text"
              />

              <TextInput
                text="User ID"
                name="userId"
                value={userId}
                onChange={handleChange}
                placeholder="Enter User ID"
                type="text"
              />

              <TextInput
                text="Ikons Balance"
                name="ikoinsBalance"
                value={parseInt(ikoinsBalance)}
                onChange={handleChange}
                placeholder="Enter Ikons Balance"
                type="number"
              />

              <TextInput
                text="Membership Start Date"
                name="membershipStartDate"
                value={moment(membershipStartDate).format("YYYY-MM-DD")}
                onChange={handleChange}
                placeholder="Enter Membership Start Date"
                type="date"
              />

              <SelectInputMember
                text="Membership Tier"
                name="membershipTier"
                value={membershipTier}
                onChange={handleChange}
                placeholder="Select Membership Tier"
                data={
                  memebershipData && memebershipData.length > 0
                    ? memebershipData
                    : []
                }
              />
              <SelectInput
                text="Membership Renewal"
                name="membershipRenewal"
                value={membershipRenewal}
                onChange={handleChange}
                placeholder="Select Membership Renewal"
                data={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />

              <SelectInput
                text="Is Partner"
                name="isPartner"
                value={isPartner}
                onChange={handleChange}
                placeholder="Select Is Partner"
                data={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />
              <SelectInput
                text="Is Admin"
                name="isAdmin"
                value={isAdmin}
                onChange={handleChange}
                placeholder="Select Is Admin"
                data={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />
              <SelectInput
                text="Is Active"
                name="isActive"
                value={isActive}
                onChange={handleChange}
                placeholder="Select Is Deleted"
                data={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />

              <TextInput
                text="Membership Nominee"
                name="membershipNominee"
                value={membershipNominee}
                onChange={handleChange}
                placeholder="Enter Membership Nominee"
                type="text"
              />
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="mt-3 mb-6 text-sm font-bold uppercase text-blueGray-400">
              Wordpress Registration
            </h6>
            <div className="flex flex-wrap">
              <TextInput
                text="First Name"
                name="first_name"
                value={first_name}
                onChange={(e) =>
                  setWpValue({ ...wpValue, first_name: e.target.value })
                }
                placeholder="Enter First Name"
                type="text"
              />

              <TextInput
                text="Last Name"
                name="last_name"
                value={last_name}
                onChange={(e) =>
                  setWpValue({ ...wpValue, last_name: e.target.value })
                }
                placeholder="Enter Last Name"
                type="text"
              />
            </div>

            <Button text="Update User" onClick={handleSubmit} />
          </form>
        </div>
      </div>
    </>
  );
}
