import React, { useEffect, useState } from "react";
import Button from "../Common/Button";
import { getBanner, updateBanner } from "../../apis/userApi";
import { image } from "../../apis/membershipApi";
import { notify } from "../../util";
import { useSelector } from "react-redux";
import { CircleLoader } from "react-spinners";
import loader from "../../assets/loading.gif";
// components

export default function CardSettings() {
  const [banner, setBanner] = React.useState("");
  const [newBanner, setNewBanner] = React.useState("");
  const [preview, setPreview] = useState();
  const [loading, setLoading] = useState(false);

  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  const getBanners = async () => {
    setLoading(true);
    const { data } = await getBanner();

    console.log(data);
    setBanner(data.banner[0]);
    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoading(true);
    getBanners();
    setLoading(false);
  }, []);

  const handleImageChange = (e) => {
    setPreview(URL.createObjectURL(e.target.files[0]));
    setNewBanner(e.target.files[0]);
    console.log(banner);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", newBanner);
      // console.log(formData);

      const res = await image(formData);

      if (res) {
        const { data } = await updateBanner(
          banner._id,
          res.data.imageUrl,
          token
        );
        if (data) {
          getBanners();
          setLoading(false);
          notify("Banner updated successfully", "success");
          setNewBanner("");
        }
      }
    } catch (error) {
      setLoading(false);
      notify(error.message, "error");
    }
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100">
        <div className="px-6 py-6 mb-0 bg-white rounded-t">
          <div className="flex justify-between text-center">
            <h6 className="text-xl font-bold text-blueGray-700">
              Upload New Banner
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
          <form>
            <div className="flex flex-wrap content-center">
              <div className="w-full px-4 py-4 lg:w-6/12">
                <div class="flex flex-wrap justify-center">
                  {loading ? (
                    <CircleLoader size={50} color={"#123abc"} loading={true} />
                  ) : (
                    <img
                      src={banner.image ? banner.image : loader}
                      class="p-1  border rounded max-w-sm"
                      alt="..."
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-wrap content-center">
                <div className="w-full px-4 lg:w-12/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-3 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="grid-password"
                    >
                      Select Your Image
                    </label>
                    <input
                      class="form-control
   border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      type="file"
                      id="formFile"
                      encType="multipart/form-data"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Button
              text={loading ? "Loading...." : "Update"}
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
    </>
  );
}
