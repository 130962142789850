import React from "react";
import icon from "../../assets/output-onlinegiftools.gif";
import AppleIcon from "../../assets/img/apple-logo.png";
import PlayStoreIcon from "../../assets/img/google-play.png";
import { Link } from "react-router-dom";

export default function Login({ history }) {
  return (
    <section className="relative w-full h-full min-h-screen py-40">
      <div
        className="absolute top-0 w-full h-full bg-no-repeat bg-blueGray-800 bg-full"
        style={{
          backgroundImage:
            "url(" + require("assets/img/register_bg_2.png").default + ")",
        }}
      >
        <div className="container h-full px-4 mx-auto">
          <div className="flex items-center content-center justify-center h-full">
            <div className="w-full px-4 lg:w-4/12">
              <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-200">
                <div className="flex-auto px-10 py-10 pt-4 lg:px-10">
                  <div className="mb-4 font-bold text-center text-blueGray-800">
                    <h1>Iconik Memebers</h1>
                  </div>
                  <img src={icon} alt="image" />
                  <div className="mt-6 text-center">
                    <h1>
                      Congratulations! You have successfully registered on
                      Iconik
                    </h1>
                  </div>
                  <form>
                    <div className="mt-6 text-center">
                      {/* Congraulation */}
                    </div>
                    <div className="relative w-full mt-4 mb-4 text-center">
                      <Link
                        path="/"
                        onClick={() => history.push("/")}
                        className="mt-10"
                      >
                        Back
                      </Link>
                      <p className="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold uppercase text-blueGray-800">
                        Get the app
                      </p>
                    </div>
                    <div className="flex">
                      <div class="hero container max-w-screen-lg mx-auto pb-10">
                        <a href="https://www.apple.com/app-store/">
                          <img
                            class="mx-auto"
                            width="30%"
                            src={AppleIcon}
                            alt="Apple App Store"
                          />
                        </a>
                      </div>
                      <div class="hero container max-w-screen-lg mx-auto pb-10">
                        <a href="https://play.google.com/store/apps">
                          <img
                            width="30%"
                            class="mx-auto"
                            src={PlayStoreIcon}
                            alt="google Play Store"
                          />
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
