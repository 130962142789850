import axios from "../axios";
import Axios from "axios";

export const getHistory = (token) => {
  return axios.get(`/users/allHistory`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPointAdjustmentHistory = (token) => {
  return axios.get(`/users/coinsadjustments`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createPointAdjustment = async (coinsadjustment, token) => {
  return axios.post(`/users/coinsadjustment`, coinsadjustment, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
