// components
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "../Common/Button";
import TextInput from "../Common/TextInput";
import SelectInput from "../Common/SelectInput";
import Select from "react-select";
import { notify } from "../../util";
import { getUsers } from "../../apis/userApi";
import { createPointAdjustment } from "../../apis/historyApi";
import { useHistory } from "react-router";

export default function CardSettings() {
  // global state
  const {
    auth: { token, _id },
  } = useSelector((state) => ({ ...state }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const { data } = await getUsers(token);
    setUserData(data);
  }, [token]);

  const history = useHistory();

  // State
  const [pointData, setPointData] = React.useState({
    loggedInUserId: _id,
    userId: "",
    transactionType: "",
    transactionDate: "",
    amount: "",
    notes: "",
    referenceNumber: "",
  });

  const [userData, setUserData] = React.useState();

  const {
    loggedInUserId,
    userId,
    transactionType,
    transactionDate,
    amount,
    notes,
    referenceNumber,
  } = pointData;

  const [selectedOption, setSelectedOption] = React.useState(null);

  var options =
    userData && userData.length > 0
      ? userData.map((item) => {
          return { value: item._id, label: `${item.userId} - ${item.name}` };
        })
      : [];

  const handleSelectedOption = (selectedOption) => {
    selectedOption?.value &&
      setPointData({ ...pointData, userId: selectedOption.value });
  };

  const handleChange = (e) => {
    setPointData({ ...pointData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validataion

    if (
      !loggedInUserId ||
      !userId ||
      !transactionType ||
      !transactionDate ||
      !notes ||
      !referenceNumber
    ) {
      notify("Please fill all fields", "error");
      return;
    }

    // Api call
    await createPointAdjustment(pointData, token)
      .then((res) => {
        notify("Point adjustment created successfully", "success");
        history.push("/admin/memberpoints");
      })
      .catch((err) => {
        if (err.response.status === 406) {
          notify("Minimum points balance can not be less than zero", "error");
        }
      });
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100">
        <div className="px-6 py-6 mb-0 bg-white rounded-t">
          <div className="flex justify-between text-center">
            <h6 className="text-xl font-bold text-blueGray-700">
              Member Points History
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
          <form>
            <div className="flex flex-wrap">
              <div className="w-full px-4 lg:w-6/12">
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="grid-password"
                  >
                    User ID
                  </label>
                  <Select
                    defaultValue={selectedOption}
                    onChange={handleSelectedOption}
                    options={options}
                  />
                </div>
              </div>
              <SelectInput
                text="Transaction Type"
                name="transactionType"
                value={transactionType}
                onChange={handleChange}
                placeholder="Select Transaction Type"
                data={[
                  { value: "debit", label: "Debit" },
                  { value: "credit", label: "Credit" },
                ]}
              />

              <TextInput
                text="Date"
                name="transactionDate"
                value={transactionDate}
                onChange={handleChange}
                placeholder="Enter Date"
                type="date"
              />
              <TextInput
                text="Amount"
                name="amount"
                value={parseInt(amount)}
                onChange={handleChange}
                placeholder="Enter Amount"
                type="number"
              />
              <TextInput
                text="Notes"
                name="notes"
                value={notes}
                onChange={handleChange}
                placeholder="Enter Notes"
                type="text"
              />
              <TextInput
                text="Reference Number"
                name="referenceNumber"
                value={referenceNumber}
                onChange={handleChange}
                placeholder="Enter Reference Number"
                type="text"
              />
            </div>

            <Button text="Create Points Adjustment" onClick={handleSubmit} />
          </form>
        </div>
      </div>
    </>
  );
}
