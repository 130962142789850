// components
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import Button from "../Common/Button";
import TextInput from "../Common/TextInput";
import SelectInputMember from "../Common/SelectInputMember";
import SelectInput from "../Common/SelectInput";
import { notify } from "../../util";
import { createUser } from "../../apis/userApi";
import { createWpUser } from "../../apis/wpApi";
import { createExpWpUser } from "../../apis/expApi";
import { getMembership } from "../../apis/membershipApi";
import { useHistory } from "react-router";

export default function CardSettings() {
  // global state
  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const { data } = await getMembership(token);
    setMembershipData(data);
  }, [token]);

  const history = useHistory();

  // State
  const [centralData, setCentralData] = React.useState({
    name: "",
    email: "",
    contactNumber: "",
    userId: "",
    ikonsBalance: "",
    membershipStartDate: "",
    membershipTier: "",
    membershipRenewal: "",
    password: "",
    isPartner: "",
    isAdmin: "",
    membershipNominee: "",
  });

  const [memebershipData, setMembershipData] = React.useState();

  const [wpValue, setWpValue] = React.useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
  });

  const {
    name,
    email,
    contactNumber,
    userId,
    ikoinsBalance,
    membershipStartDate,
    membershipTier,
    membershipRenewal,
    isPartner,
    password,
    isAdmin,
    membershipNominee,
  } = centralData;

  const handleChange = (e) => {
    setCentralData({ ...centralData, [e.target.name]: e.target.value });
  };

  // password
  // wpValue["password"] = password;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // validataion
      if (password) {
        const passwordFormat = /^[A-Za-z0-9.]{6,}$/;
        if (!password.match(passwordFormat)) {
          notify(
            "Password must contain at least 6 characters. Only . is allowed as special characters.",
            "error"
          );
          return;
        }
      }

      if (
        !name ||
        !email ||
        !userId ||
        !ikoinsBalance ||
        !membershipStartDate ||
        !membershipTier ||
        !membershipRenewal ||
        !isPartner ||
        !isAdmin
      ) {
        notify("Please fill all the fields", "error");
        return;
      }
      if (
        !wpValue.email ||
        !wpValue.first_name ||
        !wpValue.last_name ||
        !wpValue.username
      ) {
        notify("Please fill all the fields", "error");
        return;
      }

      if (wpValue.email !== email) {
        notify("Email does not match", "error");
        return;
      }
      if (password) {
        wpValue.password = password;
      }

      // Api call
      const response = await createUser(centralData, token);
      const wpResponse = await createWpUser(wpValue, token);
      const expResponse = await createExpWpUser(wpValue, token);

      notify("User created successfully", "success");
      history.push("/admin/Users");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100">
        <div className="px-6 py-6 mb-0 bg-white rounded-t">
          <div className="flex justify-between text-center">
            <h6 className="text-xl font-bold text-blueGray-700">
              Create Account
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
          <form>
            <h6 className="mt-3 mb-6 text-sm font-bold uppercase text-blueGray-400">
              Centralized User Information
            </h6>
            <div className="flex flex-wrap">
              <TextInput
                text="Name *"
                name="name"
                value={name}
                onChange={handleChange}
                placeholder="Enter Name"
                type="text"
              />

              <TextInput
                text="Email *"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Enter Email"
                type="email"
              />
              <TextInput
                text="Password"
                name="password"
                value={password}
                onChange={handleChange}
                placeholder="Enter Password"
                type="password"
              />

              <TextInput
                text="Contact Number *"
                name="contactNumber"
                value={contactNumber}
                onChange={handleChange}
                placeholder="Enter Contact Number"
                type="text"
              />

              <TextInput
                text="User ID *"
                name="userId"
                value={userId}
                onChange={handleChange}
                placeholder="Enter User ID"
                type="text"
              />

              <TextInput
                text="Ikons Balance *"
                name="ikoinsBalance"
                value={parseInt(ikoinsBalance)}
                onChange={handleChange}
                placeholder="Enter Ikons Balance"
                type="number"
              />

              <TextInput
                text="Membership Start Date *"
                name="membershipStartDate"
                value={membershipStartDate}
                onChange={handleChange}
                placeholder="Enter Membership Start Date"
                type="date"
              />

              <SelectInputMember
                text="Membership Tier *"
                name="membershipTier"
                value={membershipTier}
                onChange={handleChange}
                placeholder="Select Membership Tier"
                data={
                  memebershipData && memebershipData.length > 0
                    ? memebershipData
                    : []
                }
              />
              <SelectInput
                text="Membership Renewal *"
                name="membershipRenewal"
                value={membershipRenewal}
                onChange={handleChange}
                placeholder="Select Membership Renewal"
                data={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />

              <SelectInput
                text="Is Partner *"
                name="isPartner"
                value={isPartner}
                onChange={handleChange}
                placeholder="Select Is Partner"
                data={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />
              <SelectInput
                text="Is Admin "
                name="isAdmin"
                value={isAdmin}
                onChange={handleChange}
                placeholder="Select Is Admin"
                data={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
              />

              <TextInput
                text="Membership Nominee"
                name="membershipNominee"
                value={membershipNominee}
                onChange={handleChange}
                placeholder="Enter Membership Nominee"
                type="text"
              />
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="mt-3 mb-6 text-sm font-bold uppercase text-blueGray-400">
              Wordpress Registration
            </h6>
            <div className="flex flex-wrap">
              <TextInput
                text="First Name"
                name="wpValue.first_name"
                value={wpValue.first_name}
                onChange={(e) =>
                  setWpValue({ ...wpValue, first_name: e.target.value })
                }
                placeholder="Enter First Name"
                type="text"
              />

              <TextInput
                text="Last Name"
                name="wpValue.last_name"
                value={wpValue.last_name}
                onChange={(e) =>
                  setWpValue({ ...wpValue, last_name: e.target.value })
                }
                placeholder="Enter Last Name"
                type="text"
              />

              <TextInput
                text="Username"
                name="wpValue.username"
                value={wpValue.username}
                onChange={(e) =>
                  setWpValue({ ...wpValue, username: e.target.value })
                }
                placeholder="Enter Username"
                type="text"
              />

              <TextInput
                text="Email"
                name="wpValue.email"
                value={wpValue.email}
                onChange={(e) =>
                  setWpValue({ ...wpValue, email: e.target.value })
                }
                placeholder="Enter Email"
                type="email"
              />
            </div>

            <Button text="Add User" onClick={handleSubmit} />
          </form>
        </div>
      </div>
    </>
  );
}
