import React, { useEffect, useState } from "react";
import { updatePassword, getUserByCode } from "../../apis/userApi";
import { updateWpPassword } from "apis/wpApi";
import { updateExpWpPassword } from "../../apis/expApi";
import { notify } from "../../util";
import AppleIcon from "../../assets/img/apple-logo.png";
import PlayStoreIcon from "../../assets/img/google-play.png";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

export default function Login({ history }) {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const checkPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[&.])(?=.{6,})/;

  const params = useParams();
  const { id } = params;

  useEffect(() => {
    getUserByCode(id)
      .then((res) => {
        if (res.data === undefined) {
          history.push("/login");
        }

        setEmail(res?.data?.data?.email);
      })
      .catch((err) => {
        notify("Something went wrong", "error");
        history.push("/");
      });
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const passwordFormat = /^[A-Za-z0-9.]{6,}$/;
    if (newPassword && !newPassword.match(passwordFormat)) {
      notify(
        "Password must contain at least 6 characters. Only . is allowed as special characters.",
        "error"
      );
      setLoading(false);
      return;
    }

    if (newPassword === confirmPassword) {
      try {
        const { res } = await updatePassword(email, newPassword);
        const { wpRes } = await updateWpPassword(email, newPassword);
        const data = await updateExpWpPassword(email, newPassword);
        notify("Password created successfully", "success");
        setLoading(false);
        history.push("/register-success");
      } catch (error) {
        setLoading(false);
      }
    } else {
      notify("Password doesn't match", "error");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container h-full px-4 mx-auto">
        <div className="flex items-center content-center justify-center h-full">
          <div className="w-full px-4 lg:w-4/12">
            <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-200">
              <div className="flex-auto px-10 py-10 pt-4 lg:px-10">
                <div className="mb-4 font-bold text-center text-blueGray-800">
                  <h1>Reset your password</h1>
                  <p
                    className="mt-2 text-left text-blueGray-500"
                    style={{
                      fontSize: "9px",
                    }}
                  >
                    <small className="text-xs text-red-500 ">* </small>
                    Password must contain at least 6 characters, and only . is
                    allowed as special characters
                  </p>
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="grid-password"
                    >
                      New Password
                      <br />
                      <small className="text-gray-600"></small>
                    </label>
                    <input
                      type="password"
                      className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="grid-password"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>

                  <div className="mt-6 text-center">
                    <button
                      className="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-blueGray-800 active:bg-blueGray-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={handleSubmit}
                    >
                      {loading ? "Loading..." : "Save"}
                    </button>
                  </div>
                  <div className="relative w-full mt-4 mb-4 text-center">
                    <Link
                      path="/"
                      onClick={() => history.push("/")}
                      className="mt-10"
                    >
                      Back
                    </Link>
                    <p className="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold uppercase text-blueGray-800">
                      Get the app
                    </p>
                  </div>
                  <div className="flex">
                    <div class="hero container max-w-screen-lg mx-auto pb-10">
                      <a href="https://www.apple.com/app-store/">
                        <img
                          class="mx-auto"
                          width="30%"
                          src={AppleIcon}
                          alt="Apple App Store"
                        />
                      </a>
                    </div>
                    <div class="hero container max-w-screen-lg mx-auto pb-10">
                      <a href="https://play.google.com/store/apps">
                        <img
                          width="30%"
                          class="mx-auto"
                          src={PlayStoreIcon}
                          alt="google Play Store"
                        />
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
