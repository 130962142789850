import React, { useState, useEffect } from "react";
import { adminLogin } from "apis/authApi";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { notify } from "../../util";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.localStorage.getItem("auth")) {
      history.push("/admin");
    }
  }, [history]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!email) {
      notify("Please enter email", "error");
      return;
    } else if (!email.match(mailformat)) {
      notify("Please enter valid email", "error");
      return;
    }

    if (!password) {
      notify("Please enter password", "error");
      return;
    }

    setLoading(true);
    try {
      const { data } = await adminLogin(email, password);
      if (data) {
        // Save token in local storage and redux state
        window.localStorage.setItem("auth", JSON.stringify(data));

        dispatch({
          type: "LOGGED_IN_USER",
          payload: data,
        });
      }
      history.push("/admin");
      notify("Login Successful", "success");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify("Invalid email or password", "error");
    }
  };
  return (
    <>
      <div className="container h-full px-4 mx-auto">
        <div className="flex items-center content-center justify-center h-full">
          <div className="w-full px-4 lg:w-4/12">
            <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-200">
              <div className="flex-auto px-10 py-10 pt-4 lg:px-10">
                <div className="mb-4 font-bold text-center text-blueGray-800">
                  <h1>Please enter your credentials.</h1>
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {/* <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="w-5 h-5 ml-1 transition-all duration-150 ease-linear border-0 rounded form-checkbox text-blueGray-700"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label>
                  </div> */}

                  <div className="mt-6 text-center">
                    <button
                      className="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-blueGray-800 active:bg-blueGray-600 hover:shadow-lg focus:outline-none"
                      type="button"
                      onClick={handleSubmit}
                    >
                      {loading ? "Loading..." : "Login"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="relative flex flex-wrap mt-6">
              <div className="w-1/2">
                <Link to="/forgot-password" className="text-blueGray-600">
                  <small className="text-white">Forgot password?</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
