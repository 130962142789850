import React from "react";
import MaterialTable from "../../components/Cards/Usertable";

// components

export default function Tables() {
  return (
    <>
      <div className="flex flex-wrap mt-12">
        <div className="w-full px-4 mb-12">
          <div>
            <button className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700">
              Add User
            </button>
          </div>
          <MaterialTable />
        </div>
      </div>
    </>
  );
}
