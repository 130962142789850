import React from "react";

function SelectInput({ value, onChange, placeholder, name, text, data }) {
  return (
    <div className="w-full px-4 lg:w-6/12">
      <div className="relative w-full mb-3">
        <label
          className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
          htmlFor="grid-password"
        >
          {text}
        </label>
        <select
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="form-select appearance-none
          
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding bg-no-repeat
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        >
          {value !== "" ? null : <option value="">Select {text}</option>}
          {data.map((item) => {
            return (
              <option key={item._id} value={item._id}>
                {item.memshipTierName}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

export default SelectInput;
