import React, { useState } from "react";
import { notify } from "../../util";
import AppleIcon from "../../assets/img/apple-logo.png";
import PlayStoreIcon from "../../assets/img/google-play.png";
import { forgotPassword, resetPassword } from "../../apis/authApi";
import { Link } from "react-router-dom";

export default function Login({ history }) {
  const [email, setEmail] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!email) {
        notify("Please enter email", "error");
        setLoading(false);
        return;
      }
      const { data } = await forgotPassword(email);
      if (data.success) {
        notify("Password Successfully Updated", "success");
        setCode(true);
        setLoading(false);
        history.push("/login");
      }
    } catch (error) {
      notify("Something went wrong please Try again!", "error");
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!emailCode) {
        notify("Please enter code", "error");
        setLoading(false);
        return;
      }
      if (!confirmPassword) {
        notify("Please enter password", "error");
        setLoading(false);
        return;
      }
      const { data } = await resetPassword(confirmPassword, emailCode);
      if (data.success) {
        notify("Password Successfully Updated", "success");
        setLoading(false);
      }
    } catch (error) {
      notify("Something went wrong", "error");
      setLoading(false);
    }
  };

  return (
    <>
      <main>
        <section className="relative w-full h-full min-h-screen py-40">
          <div
            className="absolute top-0 w-full h-full bg-no-repeat bg-blueGray-800 bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          >
            <div className="container h-full px-4 mx-auto">
              <div className="flex items-center content-center justify-center h-full">
                <div className="w-full px-4 lg:w-4/12">
                  <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-200">
                    <div className="flex-auto px-10 py-10 pt-4 lg:px-10">
                      <div className="mb-4 font-bold text-center text-blueGray-800">
                        <h1>Reset your password</h1>
                      </div>
                      <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="relative w-full mb-3">
                          <label
                            className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                            htmlFor="grid-password"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        {code ? (
                          <>
                            <div className="relative w-full mb-3">
                              <label
                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                                htmlFor="grid-password"
                              >
                                Email Code
                              </label>
                              <input
                                type="text"
                                className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                placeholder="New Password"
                                value={emailCode}
                                onChange={(e) => setEmailCode(e.target.value)}
                              />
                            </div>

                            <div className="relative w-full mb-3">
                              <label
                                className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                                htmlFor="grid-password"
                              >
                                New Password
                              </label>
                              <input
                                type="password"
                                className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                            </div>
                          </>
                        ) : null}

                        <div className="mt-6 text-center">
                          <button
                            className="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-blueGray-800 active:bg-blueGray-600 hover:shadow-lg focus:outline-none"
                            type="button"
                            onClick={code ? handleResetPassword : handleSubmit}
                          >
                            {loading ? "Loading..." : code ? "Reset" : "Send"}
                          </button>
                        </div>
                        <div className="relative w-full mt-4 mb-4 text-center">
                          <Link
                            path="/"
                            onClick={() => history.push("/")}
                            className="mt-10"
                          >
                            Back
                          </Link>
                          <p className="w-full px-6 py-3 mb-1 mr-1 text-sm font-bold uppercase text-blueGray-800">
                            Get the app
                          </p>
                        </div>
                        <div className="flex">
                          <div class="hero container max-w-screen-lg mx-auto pb-10">
                            <a href="https://www.apple.com/app-store/">
                              <img
                                class="mx-auto"
                                width="30%"
                                src={AppleIcon}
                                alt="Apple App Store"
                              />
                            </a>
                          </div>
                          <div class="hero container max-w-screen-lg mx-auto pb-10">
                            <a href="https://play.google.com/store/apps">
                              <img
                                width="30%"
                                class="mx-auto"
                                src={PlayStoreIcon}
                                alt="google Play Store"
                              />
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
