import React from "react";

function TextInput({ value, onChange, placeholder, type, name, text }) {
  return (
    <div className="w-full px-4 lg:w-6/12">
      <div className="relative w-full mb-3">
        <label
          className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
          htmlFor="grid-password"
        >
          {text}
        </label>
        <input
          name={name}
          value={value}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
        />
      </div>
    </div>
  );
}

export default TextInput;
