import React from "react";

// components

import Histotytable from "../../components/Cards/Historytable";

export default function Tables() {
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full px-4 mb-12">
          <Histotytable />
        </div>
      </div>
    </>
  );
}
