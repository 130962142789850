import React from "react";
import MemberTable from "../../components/Cards/MemberPointsTable";

// components

export default function Tables() {
  return (
    <>
      <div className="flex flex-wrap mt-12">
        <div className="w-full mb-12 px-4">
          <MemberTable />
        </div>
      </div>
    </>
  );
}
