import React from "react";

function Button({ text, onClick }) {
  return (
    <div className="w-full px-4 lg:w-6/12">
      <button
        className="px-12 py-3 mb-3 mr-2 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
        type="button"
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
}

export default Button;
