import React from "react";
import { createPopper } from "@popperjs/core";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const UserDropdown = () => {
  const { auth } = useSelector((state) => ({ ...state }));
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    // Clear Redux State
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    // Clear Local Storage
    window.localStorage.removeItem("auth");
    history.push("/login");
  };

  return (
    <>
      <a
        className="block text-white"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className={"fas fa-user mr-2 text-sm "}></i> {auth.name}
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <Link
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={logout}
        >
          Logout
        </Link>
      </div>
    </>
  );
};

export default UserDropdown;
