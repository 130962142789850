import axios from "../axios/index";

export const createWpUser = (user, token) => {
  return axios.post(`/wordpress/customer`, user, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getWpByEmail = (email, token) => {
  return axios.get(`/wordpress/customer/${email}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateWpUser = (email, user, token) => {
  return axios.put(`/wordpress/customer/${email}`, user, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateWpPassword = (email, password) => {
  return axios.get(`/wordpress/customers/${email}/${password}`);
};
