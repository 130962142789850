import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Toaster } from "react-hot-toast";
// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import ResetPassword from "layouts/ResetPassword.js";
import ForgotPassword from "views/resetpassword/ForgotPassword";
// views without layouts
import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import RegisterSuccess from "views/admin/RegisterSuccess";

import { useSelector } from "react-redux";

function App() {
  const { auth } = useSelector((state) => ({ ...state }));

  const AdminRoute = ({ ...rest }) => {
    return auth && auth.token ? <Route {...rest} /> : <Redirect to="/login" />;
  };

  return (
    <BrowserRouter>
      <Switch>
        {/* add routes with layouts */}
        {/* Protect Routes */}
        <AdminRoute path="/admin" component={Admin} />,
        <Route path="/login" component={Auth} />,
        <Route path="/reset-password/:id" component={ResetPassword} />,
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/register-success" component={RegisterSuccess} />
        <AdminRoute path="/landing" exact component={Landing} />,
        <AdminRoute path="/profile" exact component={Profile} />
        {/* Public Routes  */}
        {/* add redirect for first page */}
        <Route path="/" component={Auth} />
      </Switch>
      <Toaster position="top-right" />
    </BrowserRouter>
  );
}

export default App;
