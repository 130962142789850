import axios from "axios";

// eslint-disable-next-line no-unused-vars
const testServer = "http://localhost:5000/api/v1";
// const productionServer = "http://18.132.190.67:5000/api/v1";
const productionServer = "https://ikoins-api.iconikdubai.com/api/v1";

const api = axios.create({
  baseURL: productionServer,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
