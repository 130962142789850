import axios from "../axios/index";

export const adminLogin = (email, password) => {
  return axios.post(`/admin/login`, {
    email,
    password,
  });
};

export const forgotPassword = (email) => {
  return axios.post(`/admin/forgot-password`, {
    email,
  });
};

export const resetPassword = (password, userCode) => {
  return axios.post(`/admin/reset-password`, {
    password,
    userCode,
  });
};
