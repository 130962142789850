// import React from "react";

// // components

// import CardTable from "components/Cards/CardMembershipTier.js";

// export default function Tables() {
//   return (
//     <>
//       <div className="flex flex-wrap mt-4">
//         <div className="w-full mb-12 px-4">
//           <CardTable />
//         </div>

//       </div>
//     </>
//   );
// }

import React from "react";
import MemberTable from "../../components/Cards/MembershipTable";

// components

export default function Tables() {
  return (
    <>
      <div className="flex flex-wrap mt-12">
        <div className="w-full mb-12 px-4">
          <MemberTable />
        </div>
      </div>
    </>
  );
}
