import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import Users from "views/admin/Users.js";
import RegisterUser from "views/admin/RegisterUser.js";
import MembershipTier from "views/admin/MembershipTier.js";
import EditUser from "views/admin/EditUser";
import UserHistory from "views/admin/UserHistory.js";
import AddMembership from "views/admin/AddMembership.js";
import EditMembership from "views/admin/EditMember";
import AddMemberPoints from "views/admin/AddMemberPoints.js";
import MemberPoints from "views/admin/MemberPoints.js";
import UpdateBanner from "views/admin/UpdateBanner.js";
import UpdatePassword from "views/admin/UpdatePassword.js";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/maps" exact component={Maps} />
            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/tables" exact component={Tables} />
            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/registeruser" exact component={RegisterUser} />
            <Route
              path="/admin/membershiptier"
              exact
              component={MembershipTier}
            />
            <Route path="/admin/userhistory" exact component={UserHistory} />
            <Route path="/admin/user/:id" exact component={EditUser} />
            <Route
              path="/admin/addmembership"
              exact
              component={AddMembership}
            />
            <Route
              path="/admin/addmembership/:id"
              exact
              component={EditMembership}
            />
            <Route
              path="/admin/addmemberpoints"
              exact
              component={AddMemberPoints}
            />
            <Route path="/admin/memberpoints" exact component={MemberPoints} />
            <Route path="/admin/updatebanner" exact component={UpdateBanner} />
            <Route
              path="/admin/updatepassword"
              exact
              component={UpdatePassword}
            />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}
