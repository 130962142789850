import axios from "../axios/index";

export const createExpWpUser = (user, token) => {
  return axios.post(`/wordpress/customer/exp`, user, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getExpWpByEmail = (email, token) => {
  return axios.get(`/wordpress/customer/exp/${email}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExpWpUser = (email, user, token) => {
  return axios.put(`/wordpress/customer/exp/${email}`, user, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExpWpPassword = (email, password) => {
  return axios.get(`/wordpress/customers/exp/${email}/${password}`);
};
