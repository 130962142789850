import React, { useEffect } from "react";
import Button from "../Common/Button";
import {
  getMembershipById,
  updateMembershipById,
  image,
} from "../../apis/membershipApi";
import { useHistory } from "react-router-dom";
import { notify } from "../../util";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// components

export default function CardSettings() {
  const history = useHistory();
  const params = useParams();

  const { id } = params;

  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  const [membershipData, setMembershipData] = React.useState({
    memshipTierName: "",
    memshipTierAmount: "",
    memshipTierDuration: "",
    memshipTierImage: "",
  });
  const [cardImage, setCardImage] = React.useState("");

  const {
    memshipTierName,
    memshipTierAmount,
    memshipTierDuration,
    memshipTierImage,
  } = membershipData;

  const handleChange = (e) => {
    setMembershipData({
      ...membershipData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    setCardImage(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validataion
    if (
      !memshipTierName ||
      !memshipTierAmount ||
      !memshipTierDuration ||
      !memshipTierImage
    ) {
      notify("Please fill all fields", "error");
      return;
    }

    if (cardImage) {
      const data = {
        memshipTierName,
        memshipTierAmount,
        memshipTierDuration,
        memshipTierImage,
      };

      const res = await image(cardImage);

      data.memshipTierImage = res.data.imageUrl;

      const resp = await updateMembershipById(token, id, data);
      if (resp) {
        notify("Membership added successfully", "success");
        setTimeout(() => {
          history.push("/admin/MembershipTier");
        }, 1000);
      }
    } else {
      const data = {
        memshipTierName,
        memshipTierAmount,
        memshipTierDuration,
        memshipTierImage,
      };

      const res = await updateMembershipById(token, id, data);

      if (res) {
        notify("Membership updated successfully", "success");
        setTimeout(() => {
          history.push("/admin/MembershipTier");
        }, 1000);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getMembershipById(token, id);

      if (data) {
        setMembershipData(data);
      }
    };
    fetchData();
  }, [token, id]);

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100">
        <div className="px-6 py-6 mb-0 bg-white rounded-t">
          <div className="flex justify-between text-center">
            <h6 className="text-xl font-bold text-blueGray-700">
              Create New Membership Tier
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
          <form>
            <div className="flex flex-wrap">
              <div className="w-full px-4 lg:w-6/12">
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="grid-password"
                  >
                    MEMBERSHIP NAME
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    placeholder="Enter Membership Name"
                    name="memshipTierName"
                    value={memshipTierName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex flex-wrap">
                <div className="w-full px-4 lg:w-12/12">
                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-3 text-xs font-bold uppercase text-blueGray-600"
                      htmlFor="grid-password"
                    >
                      Select Your Image
                    </label>
                    <input
                      class="form-control
  border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      type="file"
                      id="formFile"
                      encType="multipart/form-data"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full px-4 lg:w-6/12">
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="grid-password"
                  >
                    Amount
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    placeholder="Enter Amount"
                    name="memshipTierAmount"
                    value={memshipTierAmount}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="w-full px-4 lg:w-6/12">
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="grid-password"
                  >
                    Duration
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    placeholder="Enter Duration"
                    name="memshipTierDuration"
                    value={memshipTierDuration}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <Button text="Update" onClick={handleSubmit} />
          </form>
        </div>
      </div>
    </>
  );
}
