import React from "react";
import Button from "../Common/Button";
import { updatePassword } from "../../apis/userApi";
import { updateWpPassword } from "../../apis/wpApi";
import { updateExpWpPassword } from "../../apis/expApi";
import { useHistory } from "react-router-dom";
import { notify } from "../../util";
import { useSelector } from "react-redux";

// components

export default function CardSettings() {
  const history = useHistory();

  const {
    auth: { token },
  } = useSelector((state) => ({ ...state }));

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // validataion
    if (!email || !password) {
      notify("Please fill all fields", "error");
      return;
    }

    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email && !email.match(emailFormat)) {
      notify("Invalid email", "error");
      return;
    }

    const passwordFormat = /^[A-Za-z0-9.]{6,}$/;
    if (password && !password.match(passwordFormat)) {
      notify(
        "Password must contain at least 6 characters. Only . is allowed as special characters.",
        "error"
      );
      return;
    }

    const nodeRes = await updatePassword(email, password);
    const wpRes = await updateWpPassword(email, password);
    const expRes = await updateExpWpPassword(email, password);

    if (nodeRes && wpRes && expRes) {
      notify("Password updated successfully", "success");
      setEmail("");
      setPassword("");
    }
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100">
        <div className="px-6 py-6 mb-0 bg-white rounded-t">
          <div className="flex justify-between text-center">
            <h6 className="text-xl font-bold text-blueGray-700">
              Update User Password
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
          <form>
            <div className="flex flex-wrap ">
              <div className="w-full px-4 mt-4 lg:w-6/12">
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="grid-password"
                  >
                    USER EMAIL
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    placeholder="Enter User Email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-full px-4 mt-4 lg:w-6/12">
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="grid-password"
                  >
                    NEW PASSWORD
                  </label>
                  <input
                    type="password"
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    placeholder="Enter New Password"
                    name="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <div className="block m-4 bg-white border-gray-200 rounded-lg shadow-md">
                    <p
                      style={{ color: "yellowgreen" }}
                      className="p-3 text-sm font-semibold "
                    >
                      * Password must contain at least 6 characters. Only . is
                      allowed as special characters.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Button text="Update" onClick={handleSubmit} />
          </form>
        </div>
      </div>
    </>
  );
}
