/* eslint-disable no-undef */
import { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { getMembership } from "../../apis/membershipApi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//  Icons
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const columns = [
  { title: "Membership Name", field: "memshipTierName" },
  { title: "Amount", field: "memshipTierAmount" },
  { title: "Duration", field: "memshipTierDuration" },
  { title: "Status", field: "isActive" },
];

export default function BasicTable() {
  const { auth } = useSelector((state) => ({ ...state }));
  const [users, setUsers] = useState([]);
  const history = useHistory();

  const loadMembership = () => {
    getMembership(auth.token).then((res) => {
      setUsers(
        res.data.map((i) => {
          i.memshipTierAmount = parseInt(i.memshipTierAmount).toLocaleString(
            "en-US"
          );
          if (i.isActive === true) {
            i.isActive = "Active";
            return i;
          }
          if (i.isActive === false) {
            i.isActive = "Deactive";
            return i;
          }
        })
      );
    });
  };

  useEffect(() => {
    loadMembership();
  }, []);

  return (
    <div className="App wrapper">
      <MaterialTable
        actions={[
          {
            icon: AddBox,
            tooltip: "Add Membership Tier",
            onClick: (event) => {
              history.push("/admin/addmembership");
            },
            isFreeAction: true,
          },

          {
            icon: Edit,
            tooltip: "Edit Membership Tier",
            onClick: (event, data) => {
              history.push(`/admin/addmembership/${data._id}`);
            },
          },
        ]}
        title="Membership Tiers"
        icons={tableIcons}
        columns={columns}
        data={users}
        options={{
          paging: true,
          pageSize: 20, // make initial page size
          emptyRowsWhenPaging: false, // To avoid of having empty rows
          pageSizeOptions: [20, 40, 60, 80, 100],

          actionsColumnIndex: -1,
          filterCellStyle: {
            backgroundColor: "#fafafa",
            padding: "0px",
            borderRadius: "0px",
            border: "1px solid #e0e0e0",
            width: "100%",
            height: "100%",
            margin: "0px",

            fontSize: "14px",
            fontWeight: "normal",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            color: "#212121",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            "&:hover": {
              backgroundColor: "#fafafa",
              border: "1px solid #e0e0e0",
              borderRadius: "0px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            },
          },
        }}
      />
    </div>
  );
}
