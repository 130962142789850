import axios from "../axios/index";

export const addMembership = (token, data) => {
  return axios.post("/membershiptier", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateMembershipById = (token, id, data) => {
  return axios.put(`/membershiptier/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMembershipById = (token, id) => {
  return axios.get(`/membershiptier/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMembership = (token) => {
  return axios.get("/membershiptier", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteMembership = (token, id) => {
  return axios.delete(`/membershiptier/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const image = (image) => {
  return axios.post(`/membershiptier/image`, image);
};
