import React from "react";

// components

import CardBarChart from "components/Cards/CardBarChart.js";

export default function Dashboard() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4 xl:w-12/12">
          <CardBarChart />
        </div>
      </div>
    </>
  );
}
