import axios from "../axios/index";

export const createUser = (user, token) => {
  return axios.post(`/users`, user, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUsers = (token) => {
  return axios.get(`/users`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserById = (id, token) => {
  return axios.get(`/users/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUser = (id, user, token) => {
  return axios.put(`/users/${id}`, user, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteUser = (id, token) => {
  return axios.delete(`/users/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updatePassword = (email, password) => {
  return axios.get(`/users/password/${email}/${password}`);
};

export const getBanner = () => {
  return axios.get(`/admin/banner`);
};

// export const updateBanner = (id, banner, token) => {
//   return axios.put(`/admin/banner/${id}`, banner, {
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };

export const updateBanner = (id, banner, token) => {
  console.log(banner);
  return axios.put(
    `/admin/banner/${id}`,
    { banner },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getDailyDeductedKoinz = () => {
  return axios.get(`/users/dailydeductedkoinz`);
};

export const getUserByCode = (code) => {
  return axios.get(`/users/getUser/${code}`);
};
